"use client"
import { EmsCardGroup } from "@/shared/components/ems-card-group/ems-card-group"
import { LandingPageAppCard } from "@/app/components/landing-page-app-card"
import { useTranslations } from "next-intl"
import { landingPageConfig } from "../landing-page-config"
import MuiIcon from "../components/mui-icon"
import { useSelector } from "@/lib/redux"
import { selectIsGlobalError } from "@/lib/redux/slices"
import { LandingPageApp } from "../landing-page-config"
import { selectAllNotifications, NotificationItem } from "@/lib/redux/slices"
import { getEnv } from "@/lib/get-env"
import { useEffect, useState } from "react"
import { EmsNotificationManager } from "@/shared/components/ems-notification/ems-notification-manager"

export default function Home() {
  const t = useTranslations("LandingPageAppCards")
  const isGlobalError: boolean = useSelector(selectIsGlobalError)
  const notifications: NotificationItem[] = useSelector(selectAllNotifications)

  const [appLinks, setAppLinks] = useState<Record<string, string>>({})
  useEffect(() => {
    setAppLinks({
      appManagement: getEnv("EMS_FRONTEND_APP_CARD_APP_MANAGEMENT_LINK"),
      deviceManagement: getEnv("EMS_FRONTEND_APP_CARD_DEVICE_MANAGEMENT_LINK"),
      monitoring: getEnv("EMS_FRONTEND_APP_CARD_MONITORING_LINK"),
    })
  }, [])

  return (
    <div className="p-16">
      <EmsCardGroup>
        {landingPageConfig.apps.map((app: LandingPageApp) => {
          if (appLinks[app.id]) {
            return (
              <LandingPageAppCard
                key={app.title}
                title={t(app.title)}
                subtitle={t(app.subtitle)}
                description={t(app.description)}
                link={appLinks[app.id]}
                mainIcon={<MuiIcon name={app.mainIcon} className="w-12 h-12" />}
                disabled={app.disabled || isGlobalError}
              />
            )
          } else {
            return null
          }
        })}
      </EmsCardGroup>
      <EmsNotificationManager notifications={notifications} data-testid={"notification-manager"} />
    </div>
  )
}
