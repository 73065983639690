"use client"
import React, { PropsWithChildren, ReactNode } from "react"
import { Link, Divider } from "@nextui-org/react"
import NextLink from "next/link"
import { EmsCard } from "@/shared/components/ems-card/ems-card"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

export type LandingPageAppCardProps = {
  title: string
  subtitle: string
  description: string
  mainIcon: ReactNode
  link: string
  disabled?: boolean
  "aria-label"?: React.ComponentProps<"button">["aria-label"]
}

export function LandingPageAppCard({
  title,
  subtitle,
  description,
  mainIcon,
  link,
  disabled,
  "aria-label": ariaLabel,
}: PropsWithChildren<LandingPageAppCardProps>) {
  return (
    <Link
      isDisabled={disabled}
      href={link}
      aria-label={ariaLabel}
      tabIndex={disabled ? -1 : 0}
      isExternal
      className="text-ems-on-surface hover:opacity-100 data-[hover=true]:!opacity-100 active:!opacity-100 data-[disabled=true]:opacity-100"
    >
      <EmsCard disabled={disabled}>
        <div className="flex gap-4 w-[22rem]">
          <div className="self-center">{mainIcon}</div>
          <div className="flex-grow flex flex-col py-4 gap-2">
            <p className="text-card-title">{title}</p>
            <p className="text-caption-medium">{subtitle}</p>
          </div>
          <OpenInNewIcon></OpenInNewIcon>
        </div>
        <Divider className="bg-line-medium"></Divider>
        <p className="text-body-medium-length mt-4">{description}</p>
      </EmsCard>
    </Link>
  )
}
