"use client"

import React, { PropsWithChildren, ReactElement } from "react"
import { EmsNotificationMessage } from "./ems-notification-message"
import { NotificationItem } from "@/shared/lib/redux/slices/notifications"
import { CommonComponentProps } from "@/shared/lib/component-types"

export type EmsNotificationManagerProps = CommonComponentProps & {
  notifications: NotificationItem[]
}

export function EmsNotificationManager({
  notifications,
  "data-testid": dataTestId,
}: PropsWithChildren<EmsNotificationManagerProps>): ReactElement {
  return (
    <div className="absolute top-14 right-0 p-6 pt-4 flex flex-col gap-2 overflow-hidden z-50" data-testid={dataTestId}>
      {notifications.map((notification: NotificationItem) => {
        return (
          <div key={notification.id} className="relative animate-notificationMessageFadeIn">
            <EmsNotificationMessage
              id={notification.id}
              messageType={notification.notificationType}
              messageKey={notification.messageKey}
              data-testid={"notification-message-" + notification.id}
              translate={notification.translate}
            ></EmsNotificationMessage>
          </div>
        )
      })}
    </div>
  )
}
