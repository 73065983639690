import json from "../../landing-page-config.json"

export const landingPageConfig: LandingPageConfig = json

type I18NKey = string
export type LandingPageApp = {
  id: string
  title: I18NKey
  subtitle: I18NKey
  description: I18NKey
  mainIcon: string
  link: string
  disabled?: boolean
  "aria-label"?: I18NKey
}

type LandingPageConfig = {
  apps: LandingPageApp[]
}
