import { IconTypeMap } from "@mui/material"
import * as MUIcon from "@mui/icons-material"

type IconProps = IconTypeMap["props"]

interface MuiIconProps extends IconProps {
  name: string | undefined
  className?: string
}

export default function MuiIcon(props: MuiIconProps) {
  const { name } = props
  const Icon = MUIcon[name as keyof typeof MUIcon]
  if (Icon == null) {
    throw `There is no "${name}" Icon`
  }
  return <Icon {...props} />
}
