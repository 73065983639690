"use client"
import React, { PropsWithChildren, ReactElement } from "react"
import { Card, CardBody } from "@nextui-org/react"
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined"
import { CommonComponentProps } from "@/shared/lib/component-types"
import { NotificationType, removeNotification } from "@/shared/lib/redux/slices/notifications"
import { useTranslations } from "next-intl"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { EmsIconButton } from "@/shared/components/ems-icon-button/ems-icon-button"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch } from "react-redux"

export type EmsNotificationMessageProps = CommonComponentProps & {
  id: string
  messageType: NotificationType
  messageKey?: string
  translate?: boolean
}

function NotificationMessageIcon({ messageType }: { messageType: NotificationType }): ReactElement {
  switch (messageType) {
    case "error":
    case "global_error":
      return <DangerousOutlinedIcon className="w-6 h-6 text-ems-status-error" />
    case "success":
      return <TaskAltIcon className="w-6 h-6 text-ems-status-success" />
    default:
      return <DangerousOutlinedIcon className="w-6 h-6 text-ems-status-error" />
  }
}

export function EmsNotificationMessage({
  id,
  messageType,
  messageKey,
  translate = true,
  "data-testid": dataTestId,
}: PropsWithChildren<EmsNotificationMessageProps>): ReactElement {
  const t = useTranslations("EmsNotificationMessage")
  const dispatch = useDispatch()

  function closePressed() {
    dispatch(removeNotification({ itemOrId: id }))
  }

  return (
    <Card
      className={`w-[28.125rem] min-h-[56px] h-auto p-0
        bg-ems-surface border border-ems-line-fair border-l-4 ${messageType === "success" ? "border-l-ems-status-success" : "border-l-ems-status-error"}
        shadow-[-2px_3px_6px_0_rgba(0,0,0,0.11),1px_-1px_6px_0_rgba(0,0,0,0.07)]`}
      radius="none"
      shadow="none"
      data-testid={dataTestId}
    >
      <CardBody className="flex justify-between flex-row p-2 pl-1">
        <div className="flex flex-row gap-2">
          <div className="flex justify-center items-center w-10 h-10">
            <NotificationMessageIcon messageType={messageType}></NotificationMessageIcon>
          </div>
          <div className="flex items-center py-1.5 text-ems-on-surface whitespace-pre-line">
            <p>{translate ? t(messageKey) : messageKey}</p>
          </div>
        </div>
        {messageType !== "global_error" ? (
          <EmsIconButton onPress={closePressed}>
            <CloseIcon className="w-6 h-6" />
          </EmsIconButton>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  )
}
