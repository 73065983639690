import React, { PropsWithChildren } from "react"
import { CommonComponentProps } from "@/shared/lib/component-types"

type Props = CommonComponentProps

export function EmsCardGroup({ id, "data-testid": dataTestid, children }: PropsWithChildren<Props>) {
  return (
    <div id={id} data-testid={dataTestid} className="flex flex-wrap gap-4 items-stretch">
      {children}
    </div>
  )
}
